import config from "@/config";
import http from "@/utils/request";

export default {
	list: {
		url: `${config.API_URL}/admin/order/list`,
		name: "订单列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	refund: {
		url: `${config.API_URL}/admin/order/refund`,
		name: "订单退款",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
};
