import config from "@/config";
import http from "@/utils/request";

export default {
	cate: {
		add: {
			url: `${config.API_URL}/admin/wooden_fish_background_cate/add`,
			name: "增加背景分类",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/wooden_fish_background_cate/del`,
			name: "修改背景分类",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/wooden_fish_background_cate/edit`,
			name: "修改背景分类",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/wooden_fish_background_cate/list`,
			name: "背景分类列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	add: {
		url: `${config.API_URL}/admin/wooden_fish_background/add`,
		name: "新增背景",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	del: {
		url: `${config.API_URL}/admin/wooden_fish_background/del`,
		name: "删除背景",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/wooden_fish_background/edit`,
		name: "编辑背景",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/wooden_fish_background/detail`,
		name: "背景详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	list: {
		url: `${config.API_URL}/admin/wooden_fish_background/list`,
		name: "背景列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
};
