import config from "@/config";
import http from "@/utils/request";

export default {
	app: {
		cates: {
			url: `${config.API_URL}/admin/wallpaper/cates`,
			name: "采集平台分类",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	crawler: {
		cates: {
			url: `${config.API_URL}/admin/wallpaper/from_cates`,
			name: "采集平台分类",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	wallpaper: {
		list: {
			url: `${config.API_URL}/admin/order/list`,
			name: "壁纸列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		cate: {},
	},
	video: {
		list: {
			url: `${config.API_URL}/admin/wallpaper_video/list`,
			name: "视频列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		status: {
			url: `${config.API_URL}/admin/wallpaper_video/status`,
			name: "批量审核",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
	},
	cates: {
		add: {
			url: `${config.API_URL}/admin/wallpaper/cates/add`,
			name: "添加分类",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/wallpaper/cates/edit`,
			name: "修改分类",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/wallpaper/cates/del`,
			name: "添加分类",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
	},
};
