import config from "@/config";
import http from "@/utils/request";

export default {
	cate: {
		add: {
			url: `${config.API_URL}/admin/mural_cate/add`,
			name: "增加壁画分类",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/mural_cate/del`,
			name: "修改壁画分类",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/mural_cate/edit`,
			name: "修改壁画分类",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/mural_cate/list`,
			name: "壁画分类列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	add: {
		url: `${config.API_URL}/admin/mural/add`,
		name: "新增壁画",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	del: {
		url: `${config.API_URL}/admin/mural/del`,
		name: "删除壁画",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/mural/edit`,
		name: "编辑壁画",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/mural/detail`,
		name: "壁画详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	list: {
		url: `${config.API_URL}/admin/mural/list`,
		name: "壁画列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
};
