import config from "@/config";
import http from "@/utils/request";

export default {
	list: {
		url: `${config.API_URL}/admin/user/list`,
		name: "用户列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/user/edit`,
		name: "修改用户",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	getbook: {
		url: `${config.API_URL}/admin/guest_book/list`,
		name: "意见反馈",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	getbookOpt: {
		url: `${config.API_URL}/admin/guest_book/opt`,
		name: "意见回复",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
};
