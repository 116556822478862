import config from "@/config";
import http from "@/utils/request";

export default {
	add: {
		url: `${config.API_URL}/admin/article/add`,
		name: "新增文章",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/article/edit`,
		name: "修改文章",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	list: {
		url: `${config.API_URL}/admin/article/list`,
		name: "文章列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/article/detail`,
		name: "文章详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	delete: {
		url: `${config.API_URL}/admin/article/del`,
		name: "文章删除",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
};
