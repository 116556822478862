import config from "@/config";
import http from "@/utils/request";

export default {
	uploadFile: {
		url: `${config.API_URL}/attachment/upload`,
		name: "上传文件",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	count: {
		url: `${config.API_URL}/admin/home/count`,
		name: "首页统计",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	smsList: {
		url: `${config.API_URL}/admin/sms/list`,
		name: "短信列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	admin: {
		login: {
			url: `${config.API_URL}/admin/login`,
			name: "用户登录",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		add: {
			url: `${config.API_URL}/admin/admin`,
			name: "添加管理",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/admin`,
			name: "管理列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/admin/edit`,
			name: "管理列表",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
		delete: {
			url: `${config.API_URL}/admin/admin/del`,
			name: "删除管理",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		role: {
			url: `${config.API_URL}/admin/role`,
			name: "角色列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		info: {
			url: `${config.API_URL}/admin/get_info`,
			name: "账号信息",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	book: {
		add: {
			url: `${config.API_URL}/admin/buddhist_sutras/add`,
			name: "添加佛经",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/buddhist_sutras/list`,
			name: "佛经列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/buddhist_sutras/del`,
			name: "删除佛经",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/buddhist_sutras/edit`,
			name: "修改佛经",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
	},
	song: {
		add: {
			url: `${config.API_URL}/admin/buddhist_songs/add`,
			name: "添加佛歌",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/buddhist_songs/list`,
			name: "佛歌列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/buddhist_songs/del`,
			name: "删除佛歌",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/buddhist_songs/edit`,
			name: "修改佛歌",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
	},
};
