import config from "@/config";
import http from "@/utils/request";

export default {
	list: {
		url: `${config.API_URL}/admin/app_channel/list`,
		name: "渠道列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/app_channel/edit`,
		name: "修改渠道",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	add: {
		url: `${config.API_URL}/admin/app_channel/add`,
		name: "增加渠道",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	del: {
		url: `${config.API_URL}/admin/app_channel/del`,
		name: "删除渠道",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/app_channel/detail`,
		name: "渠道详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},

	appGoods: {
		url: `${config.API_URL}/admin/goods_app_channel/list`,
		name: "销售渠道详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	appGoodsSet: {
		url: `${config.API_URL}/admin/goods_app_channel/setting`,
		name: "销售渠道设置",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
};
