import config from "@/config";
import http from "@/utils/request";

export default {
	add: {
		url: `${config.API_URL}/admin/wooden_fish/add`,
		name: "新增木鱼",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	list: {
		url: `${config.API_URL}/admin/wooden_fish/list`,
		name: "木鱼列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/wooden_fish/detail`,
		name: "木鱼详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/wooden_fish/edit`,
		name: "编辑木鱼",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	del: {
		url: `${config.API_URL}/admin/wooden_fish/del`,
		name: "删除木鱼",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
};
