import config from "@/config";
import http from "@/utils/request";

export default {
	cate: {
		add: {
			url: `${config.API_URL}/admin/buddha_background_cate/add`,
			name: "增加壁纸分类",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
		del: {
			url: `${config.API_URL}/admin/buddha_background_cate/del`,
			name: "修改壁纸分类",
			delete: async function (data, config = {}) {
				return await http.delete(this.url, data, config);
			},
		},
		edit: {
			url: `${config.API_URL}/admin/buddha_background_cate/edit`,
			name: "修改壁纸分类",
			put: async function (data, config = {}) {
				return await http.put(this.url, data, config);
			},
		},
		list: {
			url: `${config.API_URL}/admin/buddha_background_cate/list`,
			name: "壁纸分类列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
	},
	add: {
		url: `${config.API_URL}/admin/buddha_background/add`,
		name: "新增壁纸",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	del: {
		url: `${config.API_URL}/admin/buddha_background/del`,
		name: "删除壁纸",
		delete: async function (data, config = {}) {
			return await http.delete(this.url, data, config);
		},
	},
	edit: {
		url: `${config.API_URL}/admin/buddha_background/edit`,
		name: "编辑壁纸",
		put: async function (data, config = {}) {
			return await http.put(this.url, data, config);
		},
	},
	detail: {
		url: `${config.API_URL}/admin/buddha_background/detail`,
		name: "壁纸详情",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	list: {
		url: `${config.API_URL}/admin/buddha_background/list`,
		name: "壁纸列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},

	appCates: {
		url: `${config.API_URL}/admin/wallpaper/cates`,
		name: "壁纸分类列表",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	status: {
		list: {
			url: `${config.API_URL}/admin/wallpaper/list`,
			name: "壁纸列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		cates: {
			url: `${config.API_URL}/admin/wallpaper/from_cates`,
			name: "壁纸分类列表",
			get: async function (data, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		submit: {
			url: `${config.API_URL}/admin/wallpaper/status`,
			name: "批量审核",
			post: async function (data, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
	},
};
